import { Button, Form, Input, Modal, Popconfirm, Space, Spin, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import '../styles/StockRequestList.css'; // Import a CSS file for custom styles

function StockRequestList() {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    var sessionID = sessionStorage.getItem('SessionID');

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const response = await fetch(`https://fleet-installer-assistant.handsonsystems.com/api/stock/${sessionID}`);
            const data = await response.json();
            data.sort((a, b) => a.name.localeCompare(b.name));
            setItems(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    async function handleModalOk() {
        if (selectedItem !== null) {
            const updatedItem = { ...selectedItem, name: editedName };
            await fetch(`https://fleet-installer-assistant.handsonsystems.com/api/stock/${selectedItem._id}/${sessionID}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedItem),
            });
            fetchData();
            setEditModalVisible(false);
        } else {
            handleAddStock();
        }
    }

    const handleModalCancel = () => {
        setEditModalVisible(false);
    };

    async function handleEdit(data) {
        setSelectedItem(data);
        setEditedName(data.name);
        setModalTitle('Edit Stock Item');
        setEditModalVisible(true);
    }

    async function handleAddStock() {
        if (!editedName) return;
        await fetch(`https://fleet-installer-assistant.handsonsystems.com/api/stock/${sessionID}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: editedName }),
        });
        fetchData();
        setEditModalVisible(false);
    }

    async function handleShowModal() {
        setSelectedItem(null);
        setEditedName('');
        setEditModalVisible(true);
        setModalTitle('Add Stock Item');
    }

    async function handleDelete(data) {
        await fetch(`https://fleet-installer-assistant.handsonsystems.com/api/stock/${data._id}/${sessionID}`, {
            method: 'DELETE',
        });
        fetchData();
    }

    const columns = [
        {
            title: 'Stock Item',
            dataIndex: 'name',
            key: '_id',

        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '0px'
                }}>
                    <Button key={record._id + 'edit'} type="primary" icon={<EditOutlined />} size={14} onClick={() => handleEdit(record)} />
                    <Popconfirm
                        title="Delete the item"
                        description="Are you sure to delete this item?"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        onConfirm={() => handleDelete(record)}
                    >
                        <Button key={record._id + 'delete'} type="primary" danger icon={<DeleteOutlined />} size={14}/>
                    </Popconfirm>
                </Space>
            ),
            width: 110
        },
    ];

    return (
        <div className='stock-request-list'>
            <Title level={2} className="page-title">Stock Request List</Title>

            <div className='add-item-button'>
                <Button type="primary" onClick={() => handleShowModal()}>Add Item</Button>
            </div>

            {loading ? (
                <Spin size="large" />
            ) : (
                <Table rowKey="_id" columns={columns} dataSource={items} />
            )}

            <Modal
                title={modalTitle}
                open={editModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form>
                    <Form.Item label="Name">
                        <Input value={editedName || ""} onChange={e => setEditedName(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default StockRequestList;
