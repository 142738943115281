import React from 'react'
import {
    UnorderedListOutlined,
    PlusCircleOutlined,
    PieChartOutlined,
    DropboxOutlined,
    SendOutlined,
    ExportOutlined,
    ToolOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { Header } from 'antd/es/layout/layout';
import logo_dark from '../FIA-Logo-Blue-small.webp';
import logo from '../FIA-Logo-White-small.webp';
import { Image, Menu } from 'antd';
import { useMediaQuery } from 'react-responsive';

function Navbar({ theme, changeTheme }) {
    const isMobile = useMediaQuery({ maxWidth: 1170 }); // Define the mobile breakpoint as per your requirement
    const location = useLocation();
    const getSelectedKeys = () => {
        const path = location.pathname;
        const selectedKeys = [];

        items.forEach((item) => {
            if (item.children) {
                item.children.forEach((child) => {
                    if (child.label.props.to === path) {
                        selectedKeys.push(child.key);
                    }
                });
            } else {
                if (item.label.props.to === path) {
                    selectedKeys.push(item.key);
                }
            }
        });

        return selectedKeys;
    };
    const items = [
        {
            key: '1',
            // icon: <PieChartOutlined />,
            /* label: (
                <Link to="/app/dashboard">
                    Dashboard
                </Link>
            ), */
            label: (

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <Link to='/app/'>
                        {(
                            theme === 'dark' ? (
                                <Image preview={false} src={logo} width={isMobile ? 50 : 60} style={{ padding: '6px 0', paddingLeft: '10px' }} />
                            ) : (
                                <Image preview={false} src={logo_dark} width={isMobile ? 50 : 60} style={{ padding: '6px 0', paddingLeft: '10px' }} />
                            )
                        )}
                    </Link>
                </div>

            ),
        },
        {
            key: '2',
            // icon: <PieChartOutlined />,
            /* label: (
                <Link to="/app/dashboard">
                    Dashboard
                </Link>
            ), */
            label: (


                <Link to='/app/dashboard'>
                    <PieChartOutlined />
                    {!isMobile && <span >Dashboard</span>}
                </Link>

            ),
        },
        {
            key: '3',
            icon: <ToolOutlined />,
            label: 'Installations',
            children: [
                {
                    key: '3.1',
                    icon: <SendOutlined />,
                    label: (
                        <Link to="/app/updated-assets">
                            Updated Assets
                        </Link>
                    ),
                },
                {
                    key: '3.2',
                    icon: <PlusCircleOutlined />,
                    label: (
                        <Link to="/app/installations">
                            New Installations
                        </Link>
                    ),
                },
                {
                    key: '3.3',
                    icon: <PlusCircleOutlined />,
                    label: (
                        <Link to="/app/devices">
                            New Devices
                        </Link>
                    ),
                },
            ]
        },
        {
            key: '4',
            icon: <DropboxOutlined />,
            label: 'Stock',
            children: [
                {
                    key: '4.1',
                    icon: <SendOutlined />,
                    label: (
                        <Link to="/app/stock/requests">
                            Requests
                        </Link>
                    ),
                },
                {
                    key: '4.2',
                    icon: <ExportOutlined />,
                    label: (
                        <Link to="/app/stock/export">
                            Export
                        </Link>
                    ),
                },
                {
                    key: '4.3',
                    icon: <UnorderedListOutlined />,
                    label: (
                        <Link to="/app/stock/list">
                            List
                        </Link>
                    ),
                },
                {
                    key: '4.4',
                    label: (
                        <Link to='/app/stock/alerts'>
                            <WarningOutlined />
                            {!isMobile && <span >Alerts</span>}
                        </Link>

                    ),
                },
            ],
        },
    ];
    return (
        <Header style={{ display: 'flex', alignItems: 'center', padding: '0px' }}>
            {/* <div style={{justifyContent: 'center'}}>
                {(
                    theme === 'dark' ? (
                        <Image preview={false} src={logo} width={isMobile ? 50 : 60} style={{ padding: '5px 0', paddingLeft: '10px' }} />
                    ) : (
                        <Image preview={false} src={logo_dark} width={isMobile ? 50 : 60} style={{ padding: '16px 0', paddingLeft: '10px' }} />
                    )
                )}
            </div> */}
            <Menu
                theme={theme}
                mode="horizontal"
                defaultSelectedKeys={['2']}
                items={items}
                style={{ flex: 1, minWidth: 0, alignContent: 'center', justifyContent: 'center' }}
                selectedKeys={getSelectedKeys()}

            />
        </Header>

    )
}

export default Navbar