import { Card, Space, Tag, Spin, Button, Modal, Form, Input, Switch, Select, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { DropboxOutlined, ArrowLeftOutlined } from '@ant-design/icons'; // Import the ArrowLeftOutlined icon
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/StockRequestInfo.css'; // Import the stylesheet
import Title from 'antd/es/typography/Title';

const { Meta } = Card;
const { Option } = Select;

function StockRequestDetails({ theme }) {
    let { id } = useParams();
    const [items, setItems] = useState([]);
    const [editedItems, setEditedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [stockItems, setStockItems] = useState([]);
    var location = useNavigate();
    useEffect(() => {
        const sessionID = sessionStorage.getItem('SessionID');
        fetch(`https://fleet-installer-assistant.handsonsystems.com/api/stock/${sessionID}`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setStockItems(data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        const sessionID = sessionStorage.getItem('SessionID');
        setLoading(true);
        fetch(`https://fleet-installer-assistant.handsonsystems.com/api/getStockRequests/${sessionID}?key=${id}`)
            .then(response => response.json())
            .then(data => {
                setItems(data);
                setEditedItems(data);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const handleEdit = () => {
        setEditModalVisible(true);
    };

    const handleModalOk = (data) => {
        const id = editedItems[0]._id;
        const sessionID = sessionStorage.getItem('SessionID');

        fetch(`https://fleet-installer-assistant.handsonsystems.com/api/editStockRequest/${id}/${sessionID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(editedItems[0]),
        }).catch(error => {
            console.error(error);
        });
        setItems(editedItems)
        setEditModalVisible(false);
    };

    const handleModalCancel = () => {
        setEditModalVisible(false);
    };

    const handleStockItemChange = (value) => {
        const selectedStockItems = stockItems.filter(item => value.includes(item.name));
        setEditedItems(prevItems => {
            return [{ ...prevItems[0], stockList: selectedStockItems }, ...prevItems.slice(1)];
        });
    };

    const handleStockItemQuantityChange = (index, quantity) => {
        setEditedItems(prevItems => {
            const updatedStockList = prevItems[0].stockList.map((stock, i) => {
                if (i === index) {
                    return { ...stock, quantity };
                }
                return stock;
            });
            return [{ ...prevItems[0], stockList: updatedStockList }, ...prevItems.slice(1)];
        });
    };

    const handleGoBack = () => {
        location('/app/stock/requests')
    };

    return (
        <div className={`stock-container ${theme === 'dark' ? 'dark-mode' : 'light-mode'}`}>
            <Button icon={<ArrowLeftOutlined />} onClick={handleGoBack} className='backButton' />
            <br />
            <div>
                <Space direction="horizontal" size={20}>
                    <Card className={`stock-info-section ${theme}`}>
                        <Meta
                            avatar={<DropboxOutlined style={{ fontSize: '24px', verticalAlign: 'middle' }} />}
                            title={<Title level={4} style={{ display: 'inline', marginLeft: '10px' }}>Stock Request</Title>}
                            description={
                                <>
                                    {loading ? (
                                        <Spin size="large" />
                                    ) : (
                                        <>
                                            {items.length > 0 && items[0].email && (
                                                <p>Requester: {items[0].email}</p>
                                            )}
                                            {items.length > 0 && (
                                                <p>Confirmed: <Tag color={items[0].completed ? 'green' : 'red'}>
                                                    {items[0].completed ? '✓' : '✕'}
                                                </Tag></p>
                                            )}
                                            {items.length > 0 && items[0].createdAt && (
                                                <p>Time: {new Date(items[0].createdAt).toLocaleString()}</p>
                                            )}
                                            <p>Stock List:</p>
                                            <ul>
                                                {items.length > 0 && items[0].stockList.map((stock, index) => (
                                                    <li key={index}>
                                                        {stock.name} - {stock.quantity}
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </>
                            }
                        />
                        <Button type="primary" onClick={handleEdit}>Edit</Button>
                        
                    </Card>
                </Space>
            </div>
            <Modal
                title="Edit Stock Request"
                open={editModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Form>
                    <Form.Item label="Stock List">
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Select stock items"
                            onChange={handleStockItemChange}
                            value={editedItems.length > 0 ? editedItems[0].stockList.map(stock => stock.name) : []}
                        >
                            {stockItems.map(item => (
                                <Option key={item.name} value={item.name}>{item.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Quantity">
                        {editedItems.length > 0 && (
                            <ul>
                                {editedItems[0].stockList.map((stock, index) => (
                                    <li key={index} style={{ marginBottom: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ marginRight: '10px', textAlign: 'left' }}>{stock.name} :</div>
                                            <InputNumber
                                                min={1}
                                                value={stock.quantity || 1}
                                                onChange={value => handleStockItemQuantityChange(index, value)}
                                                style={{ textAlign: 'right' }}
                                            />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </Form.Item>

                    <Form.Item label="Completed">
                        <Switch
                            checked={editedItems.length > 0 ? editedItems[0].completed : false}
                            onChange={checked => {
                                setEditedItems(prevItems => [{ ...prevItems[0], completed: checked }, ...prevItems.slice(1)]);
                            }}
                        />
                    </Form.Item>


                </Form>
            </Modal>
        </div>
    );
}

export default StockRequestDetails;
