import { Space, Table, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import '../styles/Assets.css';
import Title from 'antd/es/typography/Title';

function Devices() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const SessionID = sessionStorage.getItem('SessionID');
    fetch(`https://fleet-installer-assistant.handsonsystems.com/api/getDevices/${SessionID}`)
      .then(response => response.json())
      .then(data => {
        var updatedItems = data.map(item => ({
          key: item._id,
          email: item.email,
          device_make: item.deviceMake,
          device_type: item.deviceType,
          serial_no: item.serialNo,
          time: `${new Date(item.createdAt).toLocaleDateString()} ${new Date(item.createdAt).toLocaleTimeString()}`,
        }));
        setItems(updatedItems.reverse());
        setLoading(false);
      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error(error);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: 'Technician Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Device Make',
      dataIndex: 'device_make',
      key: 'device_make',
    },
    {
      title: 'Device Type',
      dataIndex: 'device_type',
      key: 'device_type',
    },
    {
      title: 'Serial No.',
      dataIndex: 'serial_no',
      key: 'serial_no',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle" style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <a>View</a>
        </Space>
      ),
    },
  ];

  return (
    <div className='table_div'>
      <Title className='title'>Devices</Title>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table columns={columns} dataSource={items} />
      )}
    </div>
  );
}

export default Devices;
