import { Space, Table, Tag, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import '../styles/Assets.css';
import Title from 'antd/es/typography/Title';

function StockRequests() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const sessionID = sessionStorage.getItem('SessionID');
    setLoading(true); // Set loading state to true before fetching data
    fetch(`https://fleet-installer-assistant.handsonsystems.com/api/getStockRequests/${sessionID}`)
      .then(response => response.json())
      .then(data => {
        var updatedItems = data.map(item => ({
          key: item._id,
          email: item.email,
          stockList: item.stockList,
          status: item.completed,
          time: `${new Date(item.createdAt).toLocaleDateString()}`,
        }));
        setItems(updatedItems.reverse());
        setLoading(false); // Set loading state to false after data is fetched
      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error(error);
        setLoading(false); // Set loading state to false in case of error
      });
  }, []);

  const updateStatus = (data, status) => {
    const id = data.key;
    const sessionID = sessionStorage.getItem('SessionID');

    fetch(`https://fleet-installer-assistant.handsonsystems.com/api/editStockRequest/${id}/${sessionID}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        completed: status,
        stockItems: data.stockList,
      }),
    }).then(() => {
      const updatedItems = items.map(item => {
        if (item.key === id) {
          item.status = status;
        }
        return item;
      });
      setItems(updatedItems);
    })
    .catch(error => {
      console.error(error);
    });
    
  }

  const columns = [
    {
      title: 'Technician Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status ? 'green' : 'red'}>
          {status ? '✓' : '✕'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle" style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <a href={`/app/stock/requests/${record.key}`}>View</a>
          {record.status ? (
            <a onClick={() => updateStatus(record, false)}>Unapprove</a>
          ) : (
            <a onClick={() => updateStatus(record, true)}>Approve</a>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className='table_div'>
      <Title className='title'>Stock Requests</Title>
      {loading ? ( // Render loading screen if loading state is true
        <Spin size="large" />
      ) : (
        <Table columns={columns} dataSource={items} />
      )}
    </div>
  );
}

export default StockRequests;
