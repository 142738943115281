import { Card, Col, Row, Segmented, Space, Statistic, Table, Tag } from 'antd'
import React, { useState } from 'react'
import CountUp from 'react-countup';
import {
  CloudUploadOutlined,
  PlusCircleOutlined,
  DropboxOutlined
} from '@ant-design/icons';
import '../styles/Dashboard.css';

const formatter = (value) => <CountUp end={value} separator="," />;


function SetStatisticsRender({ start_date, end_date }) {
  const [data, setData] = useState(null);
  const SessionID = sessionStorage.getItem('SessionID');

  React.useEffect(() => {
    fetch(`https://fleet-installer-assistant.handsonsystems.com/api/getStatistics/${SessionID}/${start_date}/${end_date}`)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error(error));
  }, [start_date, end_date, SessionID]);

  if (!data) {
    return (
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Card bordered={false} className="custom-card">
              <Statistic title="New Installations" value={0} prefix={<PlusCircleOutlined />} formatter={formatter} />
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false} className="custom-card">
              <Statistic title="New Devices" value={0} prefix={<PlusCircleOutlined />} formatter={formatter} />
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false} className="custom-card">
              <Statistic title="Updated Assets" value={0} prefix={<CloudUploadOutlined />} formatter={formatter} />
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false} className="custom-card">
              <Statistic title="Stock Requests" value={0} prefix={<DropboxOutlined />} formatter={formatter} />
            </Card>
          </Col>

        </Row>
      </>
    );
  }

  return (
    <>
      <Row gutter={16} >
        <Col span={12}>
          <Card bordered={false} className="custom-card">
            <Statistic title="New Installations" value={data.installed_assets} prefix={<PlusCircleOutlined />} formatter={formatter} />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} className="custom-card">
            <Statistic title="New Devices" value={data.new_devices} prefix={<PlusCircleOutlined />} formatter={formatter} />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} className="custom-card">
            <Statistic title="Updated Assets" value={data.updated_assets} prefix={<CloudUploadOutlined />} formatter={formatter} />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} className="custom-card">
            <Statistic title="Stock Requests" value={data.stock_requests} prefix={<DropboxOutlined />} formatter={formatter} />
          </Card>
        </Col>

      </Row>
    </>
  );
}

function Dashboard() {
  const [option, setOption] = useState('Daily');

  function setStatistics(option) {
    setOption(option);
  }

  function renderStatistics() {
    if (option === 'Daily') {
      const currentDate = new Date();
      const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
      return <SetStatisticsRender start_date={startDate.toISOString().split('T')[0]} end_date={endDate.toISOString().split('T')[0]} />;
    } else if (option === 'Weekly') {
      const currentDate = new Date();
      const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
      const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
      return <SetStatisticsRender start_date={startDate.toISOString().split('T')[0]} end_date={endDate.toISOString().split('T')[0]} />;
    } else if (option === 'Monthly') {
      const currentDate = new Date();
      const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      return <SetStatisticsRender start_date={startDate.toISOString().split('T')[0]} end_date={endDate.toISOString().split('T')[0]} />;
    } else if (option === 'Quarterly') {
      const currentDate = new Date();
      const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
      const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      return <SetStatisticsRender start_date={startDate.toISOString().split('T')[0]} end_date={endDate.toISOString().split('T')[0]} />;
    } else if (option === 'Yearly') {
      const currentDate = new Date();
      const startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
      const endDate = new Date(currentDate.getFullYear(), 12, 0);
      return <SetStatisticsRender start_date={startDate.toISOString().split('T')[0]} end_date={endDate.toISOString().split('T')[0]} />;
    }
  }

  return (
    <div className="dashboard-container">
      <div className="segmented-container">
        <Segmented options={['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']} onChange={setStatistics} />
      </div>
      <div className="statistics-container">
        {renderStatistics()}
      </div>
    </div>
  )
}

export default Dashboard
