import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import NotLoggedInArea from './notLoggedInArea';

import LoggedInArea from './LoggedInArea';
import { ConfigProvider, theme as themeProvider } from 'antd';

function Main() {
    const sessionID = sessionStorage.getItem('SessionID');

    const navigate = useNavigate();
    const [theme, setTheme] = useState(() => {
        const storedTheme = localStorage.getItem('theme');
        return storedTheme === 'dark' ? 'dark' : 'light';
    });
    useEffect(() => {
        if (sessionID === null) {
            navigate('/auth/login');
        }
    }, [sessionID, navigate, theme])

    const changeTheme = (value) => {
        const newTheme = value ? 'dark' : 'light';
        localStorage.setItem('theme', newTheme);
        setTheme(newTheme);
    };

    return (
        <ConfigProvider
            theme={
                {
                    token: {
                        // colorTextBase: theme === 'dark' ? '#ffffff' : '#ffffff',
                        // colorBgBase: theme === 'dark' ? '#141414' : '#ffffff',
                         fontSize: 14,

                    },
                    algorithm:  theme === 'dark'?  themeProvider.darkAlgorithm: themeProvider.defaultAlgorithm,
                }
            }
        >
            <Routes>
                <Route path="/" element={<Navigate to="/app/dashboard" />} />
                <Route path="/app/*" element={<LoggedInArea theme={theme} setTheme={setTheme} changeTheme={changeTheme} />} />
                <Route path="/auth/*" element={<NotLoggedInArea />} />
            </Routes>
        </ConfigProvider>
    )
}

export default Main