import React from "react";

import { Button, Checkbox, Form, Grid, Input, theme, Typography } from "antd";

import { LockOutlined, MailOutlined } from "@ant-design/icons";
import logo from '../logo.png'
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export default function Login() {
    const { token } = useToken();
    const screens = useBreakpoint();
    const navigate = useNavigate();

    const onFinish = async (values) => {
        try {
          await axios.post('https://fleet.handsonsystems.com/api/BusinessLogic/AuthenticateUser', `${values.email} ${values.password} 0 0 0 0`, {
              headers: {
                  Accept: '*/*',
                  'Accept-Language': 'en-US,en;q=0.9',
                  'Content-Type': 'application/json',
                  'Sec-Fetch-Dest': 'empty',
                  'Sec-Fetch-Mode': 'cors',
                  'Sec-Fetch-Site': 'same-origin',
                  'X-Requested-With': 'XMLHttpRequest',
              },
          }).then(async resp => {
              await axios.get(`https://fleet-installer-assistant.handsonsystems.com/api/getUsers/${resp.data.M_SessionID}`, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                  }).then(resp2 => {
                    console.log(resp2.data);
                    for (let i = 0; i < resp2.data.length; i++) {
                        if (resp2.data[i].email === values.email) {
                            sessionStorage.setItem('SessionID', resp.data.M_SessionID);
                            navigate('/');
                        }
                    }
                  }
                )
          })

      } catch (err) {
          console.log(err);
      }
    };

    const styles = {
        container: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: "380px",
            
        },
        footer: {
            marginTop: token.marginLG,
            textAlign: "center",
            width: "100%"
        },
        forgotPassword: {
            float: "right"
        },
        header: {
            marginBottom: token.marginXL,
            justifyContent: 'center'
        },
        section: {
            backgroundColor: "#0093FF", // Set the background color to "#0093FF"
            alignItems: "center",
            display: "flex",
            height: "100vh", // Set the height to "100vh"
            overflow: "hidden", // Added this line
            // padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
        },
        text: {
            color: token.colorTextSecondary
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
            textAlign: "center",
        }
    };

    return (
        <section style={styles.section}>
          <div style={styles.container}>
            <div style={styles.header}>
            <img
                src={logo}
                width={360}
                style={{ padding: '16px 0', paddingLeft: '10px'}}
            />
    
            </div>
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              layout="vertical"
              requiredMark="optional"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: "0px" }}>
                <Button block={true} type="primary" htmlType="submit">
                  Log in
                </Button>

              </Form.Item>
            </Form>
          </div>
        </section>
      );
}