import { useState } from 'react';
import {
  CloudUploadOutlined,
  PlusCircleOutlined,
  PieChartOutlined,
  DropboxOutlined,
  SendOutlined,
  ExportOutlined,
  BgColorsOutlined
} from '@ant-design/icons';
import logo from '../logo.png';
import logo_dark from '../logo-dark.png';
import { Layout, Menu, Image, FloatButton } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const { Sider } = Layout;

const Sidebar = ({ theme, changeTheme }) => {
  const location = useLocation();

  const items = [
    {
      key: '1',
      icon: <PieChartOutlined />,
      label: (
        <Link to="/app/dashboard">
          Dashboard
        </Link>
      ),
    },
    {
      key: '2',
      icon: <CloudUploadOutlined />,
      label: (
        <Link to="/app/updated-assets">
          Updated Assets
        </Link>
      ),
    },
    {
      key: '3',
      icon: <PlusCircleOutlined />,
      label: (
        <Link to="/app/installations">
          New Installations
        </Link>
      ),
    },
    {
      key: '4',
      icon: <PlusCircleOutlined />,
      label: (
        <Link to="/app/devices">
          New Devices
        </Link>
      ),
    },
    {
      key: '5',
      icon: <DropboxOutlined />,
      label: 'Stock',
      children: [
        {
          key: '5.1',
          icon: <SendOutlined />,
          label: (
            <Link to="/app/stock/requests">
              Requests
            </Link>
          ),
        },
        {
          key: '5.2',
          icon: <ExportOutlined />,
          label: (
            <Link to="/app/stock/export">
              Export
            </Link>
          ),
        },
      ],
    }
  ];

  const [collapsed, setCollapsed] = useState(false);

  const getSelectedKeys = () => {
    const path = location.pathname;
    const selectedKeys = [];

    items.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (child.label.props.to === path) {
            selectedKeys.push(child.key);
          }
        });
      } else {
        if (item.label.props.to === path) {
          selectedKeys.push(item.key);
        }
      }
    });

    return selectedKeys;
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme={theme}>
        <div className="demo-logo-vertical">
          {!collapsed && (
            theme === 'dark' ? (
              <Image preview={false} src={logo} width={180} style={{ padding: '16px 0', paddingLeft: '10px' }} />
            ) : (
              <Image preview={false} src={logo_dark} width={180} style={{ padding: '16px 0', paddingLeft: '10px' }} />
            )
          )}
        </div>
        <Menu theme={theme} mode="inline" items={items} selectedKeys={getSelectedKeys()} />
      </Sider>
      <Layout>
        <FloatButton
          onClick={() => {
            if (theme === 'dark') {
              changeTheme(false);
            } else {
              changeTheme(true);
            }
          }}
          icon={<BgColorsOutlined />}
          type={theme === 'dark' ? 'primary' : undefined}
        />
      </Layout>
    </Layout>
  );
};

export default Sidebar;