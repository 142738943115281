import { Button, Form, Input, Modal, Popconfirm, Space, Spin, Table, Select, Alert } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import '../styles/StockRequestList.css'; // Import a CSS file for custom styles

function StockRequestList() {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [xeroItems, setXeroItems] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [newValue, setNewValue] = useState([])
    const [newMinStock, setNewMinStock] = useState();
    const [modalTitle, setModalTitle] = useState('');
    const [error, setError] = useState(false);
    var sessionID = sessionStorage.getItem('SessionID');

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const response = await fetch(`https://fleet-installer-assistant.handsonsystems.com/api/stockAlert/${sessionID}`);
            const xeroResponse = await fetch(`https://fleet-installer-assistant.handsonsystems.com/api/getXeroProducts/${sessionID}`);
            const xeroData = await xeroResponse.json();
            const data = await response.json();
            await setXeroItems(xeroData.Items);
            var itemsMapped = data.map((item) => {
                var xeroItem = xeroData.Items.find((xeroItem) => xeroItem.ItemID === item.xeroId);
                if (xeroItem) {
                    item.name = xeroItem.Name;
                    return item;
                }
            });
            setItems(itemsMapped);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }


    const handleModalCancel = () => {
        setEditModalVisible(false);
    };

    async function handleAddAlert() {
        if (!newValue || !newMinStock) {
            setError(true);
            setEditModalVisible(false);
        } else {
            await fetch(`https://fleet-installer-assistant.handsonsystems.com/api/stockAlert/${sessionID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ xeroId: newValue.ItemID, minStock: newMinStock }),
            });
            setNewValue(null);
            setNewMinStock(null)
            fetchData();
            setEditModalVisible(false);
        }
    }

    async function handleShowModal() {
        setEditModalVisible(true);
        setModalTitle('Add Stock Item');
    }

    async function handleDelete(data) {
        await fetch(`https://fleet-installer-assistant.handsonsystems.com/api/stockAlert/${data._id}/${sessionID}`, {
            method: 'DELETE',
        });
        fetchData();
    }

    const columns = [
        {
            title: 'Xero Item',
            dataIndex: 'name',
            key: '_id',

        },
        {
            title: 'Xero ID',
            dataIndex: 'xeroId',
            key: 'xeroId'
        },
        {
            title: 'Min Quantity',
            dataIndex: 'minStock',
            key: 'minStock'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '0px'
                }}>
                    <Popconfirm
                        title="Delete the item"
                        description="Are you sure to delete this item?"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        onConfirm={() => handleDelete(record)}
                    >
                        <Button key={record._id + 'delete'} type="primary" danger icon={<DeleteOutlined />} size={14} />
                    </Popconfirm>
                </Space>
            ),
            width: 110
        },
    ];

    return (
        <div className='stock-request-list'>
            {error ? (
                <Alert
                    message="Empty Fields Found"
                    type='error'
                    onClose={() => setError(false)}
                    banner
                    closable
                />
            ) : null}
            <Title level={2} className="page-title">Stock Alert List</Title>



            {loading ? (
                <Spin size="large" />
            ) : (
                <div>
                    <div className='add-item-button'>
                        <Button type="primary" onClick={() => handleShowModal()}>Add Alert</Button>
                    </div>
                    <Table rowKey="_id" columns={columns} dataSource={items} />
                    <Modal
                        title={modalTitle}
                        open={editModalVisible}
                        onOk={handleAddAlert}
                        onCancel={handleModalCancel}
                    >
                        <Form>
                            <Form.Item label="Xero Item">
                                <Select
                                    onChange={(value) => {
                                        setNewValue(xeroItems.find(item => item.Name === value));
                                    }}
                                >
                                    {xeroItems && Array.isArray(xeroItems) ? (
                                        xeroItems
                                            .filter(item => item && item.Name)
                                            .sort((a, b) => a.Name.localeCompare(b.Name))
                                            .map(item => (
                                                <Select.Option
                                                    key={item.ItemID}
                                                    value={item.Name}

                                                >
                                                    {item.Name}
                                                </Select.Option>
                                            ))
                                    ) : null}

                                </Select>
                            </Form.Item>
                            <Form.Item label="Min Stock">
                                <Input type="number" onChange={(e) => setNewMinStock(e.target.value)} />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>

            )}


        </div>
    );
}

export default StockRequestList;
