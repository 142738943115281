import { useState } from 'react';
import Dashboard from './pages/Sidebar';
import logo from './logo.png';
import { Breadcrumb, Layout, Menu, Image } from 'antd';
import { Route, Routes } from 'react-router-dom';

const { Header, Footer, Content, Sider } = Layout;


function ShowContent() {
  return <div>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/installations" element={<div>installations</div>} />
    </Routes>
  </div>
}


const App = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical">
          <Image
            preview={false}
            src={logo}
            width={180}
            style={{ padding: '16px 0', paddingLeft: '10px' }}
          />
        </div>
      </Sider>
      <Layout>
        <Header style={{ padding: 0 }} />
        <Content style={{ margin: '0 16px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              minHeight: 360,
            }}
          >
            <ShowContent />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;