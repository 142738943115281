import { Space, Table, Tag, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import '../styles/Assets.css';
import { Navigate, useNavigate } from 'react-router-dom';
import Title from 'antd/es/typography/Title';

function Assets() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  useEffect(() => {
    const sessionID = sessionStorage.getItem('SessionID');
    setLoading(true); // Set loading state to true before fetching data
    fetch(`https://fleet-installer-assistant.handsonsystems.com/api/getInstallations/${sessionID}`)
      .then(response => response.json())
      .then(data => {
        var updatedItems = data.map(item => ({
          key: item._id,
          email: item.email,
          asset: item.assetName,
          client: item.clientName,
          time: `${new Date(item.createdAt).toLocaleDateString()} ${new Date(item.createdAt).toLocaleTimeString()}`,
        }));
        setItems(updatedItems.reverse());
        setLoading(false); // Set loading state to false after fetching data
      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error(error);
        setLoading(false); // Set loading state to false if there's an error
      });
  }, []);

  const columns = [
    {
      title: 'Technician Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      key: 'asset',
    },
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle" style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <a href={`/app/updated-assets/${record.key}`}>View</a>
        </Space>
      ),
    },
  ];

  return (
    <div className='table_div'>
      <Title className='title'>Updated Assets</Title>
      {loading ? ( // Render loading screen if loading state is true
        <Spin size="large" />
      ) : (
        <Table columns={columns} dataSource={items} />
      )}
    </div>
  );
}

export default Assets;
