// App.jsx
import React, { useState } from 'react';
import { DatePicker, Space, Button, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import '../styles/StockExport.css'; // Import the stylesheet
const { RangePicker } = DatePicker;

const App = () => {
  const [timestamp1, setTimestamp1] = useState(null);
  const [timestamp2, setTimestamp2] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  const onChange = (value, dateString) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    const date1 = new Date(dateString[0]);
    setTimestamp1(date1.getTime());
    const date2 = new Date(dateString[1]);
    setTimestamp2(date2.getTime());
    debugger;
  };

  const handleDownload = () => {
    const sessionID = sessionStorage.getItem('SessionID');
    setLoading(true); // Set loading state to true
    window.location.href = `https://fleet-installer-assistant.handsonsystems.com/api/orders/download?startDate=${timestamp1}&endDate=${timestamp2}&SessionID=${sessionID}`;
    setLoading(false); // Set loading state to false
  };

  return (
    <div>
      <div className="stockContainer">
        <Space direction="vertical" size={12} align="center">
          <RangePicker
            className="date-picker"
            showTime={{
              format: 'HH:mm',
            }}
            format="YYYY-MM-DD HH:mm"
            onChange={onChange}
          />
          <Button
            className="download-button"
            type="primary"
            size="large"
            icon={<DownloadOutlined />}
            onClick={handleDownload}
          >
            Download
          </Button>
        </Space>
      </div>
      {loading && <Spin />} {/* Show loading spinner if loading state is true */}
    </div>
  );
};

export default App;
