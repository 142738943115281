// AssetDetails.jsx

import { Image, Card, Space, Spin, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { UserOutlined, CarOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/InfoPage.css'; // Import the stylesheet
import Title from 'antd/es/typography/Title';

const { Meta } = Card;

function AssetDetails({ theme }) {
    let { id } = useParams();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    var location = useNavigate();

    useEffect(() => {
        const sessionID = sessionStorage.getItem('SessionID');
        fetch(`https://fleet-installer-assistant.handsonsystems.com/api/getInstallations/${sessionID}?key=${id}`)
            .then(response => response.json())
            .then(data => {
                setItems(data);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className={`container ${theme === 'dark' ? 'dark-mode' : 'light-mode'}`}>
                <Spin size="large" />
            </div>
        );
    }

    const handleGoBack = () => {
        location('/app/updated-assets')
    };

    return (
        <div className={`container ${theme === 'dark' ? 'dark-mode' : 'light-mode'}`}>
            <Button icon={<ArrowLeftOutlined />} onClick={handleGoBack} className='backButton' />
            <br />
            <div>
                <div className="image-container">
                    <Image className='image' width={350} src={items.length > 0 ? items[0].imageurl : ''} />
                </div>
                <Space direction="horizontal" size={20}>
                    <Card className={`info-section ${theme}`}>
                        <Meta
                            avatar={<CarOutlined style={{ fontSize: '24px', verticalAlign: 'middle' }} />}
                            title={<Title level={4} style={{ display: 'inline', marginLeft: '10px' }}>Asset Information</Title>}
                            description={
                                <>
                                    <p>Asset Name: {items.length > 0 ? items[0].assetName : ''}</p>
                                    <p title={items.length > 0 ? items[0].clientName : ''}>Client Name: {items.length > 0 ? (items[0].clientName.length > 15 ? `${items[0].clientName.slice(0, 15)}...` : items[0].clientName) : ''}</p>
                                    <p title={items.length > 0 ? items[0].domainName : ''}>Domain Name: {items.length > 0 ? (items[0].domainName.length > 15 ? `${items[0].domainName.slice(0, 15)}...` : items[0].domainName) : ''}</p>
                                </>
                            }
                        />
                    </Card>
                    <Card className={`info-section ${theme}`}>
                        <Meta
                            avatar={<UserOutlined style={{ fontSize: '24px', verticalAlign: 'middle' }} />}
                            title={<Title level={4} style={{ display: 'inline', marginLeft: '10px' }}>Technician Information</Title>}
                            description={
                                <>
                                    <p>Email: {items.length > 0 ? items[0].email : ''}</p>
                                    <p>Done At: {items.length > 0 ? new Date(items[0].createdAt).toLocaleString() : ''}</p>
                                    {/* Add technician-specific details here */}
                                </>
                            }
                        />
                    </Card>
                </Space>
            </div>
        </div>
    );
}

export default AssetDetails;
