import { Route, Routes } from 'react-router-dom'
import { Col, FloatButton, Layout, Row } from 'antd'
import Sidebar from './pages/Sidebar'
import DashboardContent from './subpages/Dashboard'
import Installations from './subpages/Installations'
import Assets from './subpages/Assets'
import Devices from './subpages/Devices'
import StockRequests from './subpages/StockRequests'
import StockExport from './subpages/StockExport'
import { useEffect } from 'react'
import Navbar from './pages/Navbar'
import {
  BgColorsOutlined
} from '@ant-design/icons';
import AssetDetails from './subpages/AssetDetails'
import StockRequestDetails from './subpages/StockRequestDetails'
import StockRequestList from './subpages/StockRequestList'
import StockAlertList from './subpages/StockAlertList'

function LoggedInArea({ theme, setTheme, changeTheme }) {

  useEffect(() => {
    if (theme === 'dark') {
      document.body.style.backgroundColor = '#141414';
    } else {
      document.body.style.backgroundColor = '#ffffff';
    }
  }, [theme])

  return (
    // <Row>
    //     <Col  >
    //         <Sidebar theme={theme} setTheme={setTheme} changeTheme={changeTheme} />
    //     </Col>
    //     <Col style={{
    //       width: '84vw',
    //     }}>
    <div>
      <Navbar theme={theme} setTheme={setTheme} changeTheme={changeTheme} />
      <Routes>
        <Route path="/dashboard" element={<DashboardContent theme={theme} />} />
        <Route path="/installations" element={<Installations theme={theme} />} />
        <Route path="/installations/:id" element={<AssetDetails theme={theme} />}/>
        <Route path="/updated-assets" element={<Assets theme={theme} />} />
        <Route path="/updated-assets/:id" element={<AssetDetails theme={theme} />}/>
        <Route path="/devices" element={<Devices theme={theme} />} />
        <Route path="/devices/:id" element={<AssetDetails theme={theme} />}/>
        <Route path="/stock/requests" element={<StockRequests theme={theme} />} />
        <Route path="/stock/requests/:id" element={<StockRequestDetails theme={theme} />}/>
        <Route path="/stock/export" element={<StockExport theme={theme} />} />
        <Route path="/stock/list" element={<StockRequestList theme={theme} />} />
        <Route path="/stock/alerts" element={<StockAlertList theme={theme} />} />
        <Route path="/" element={<DashboardContent theme={theme} />} />
      </Routes>
      <Layout>
        <FloatButton
          onClick={() => {
            if (theme === 'dark') {
              changeTheme(false);
            } else {
              changeTheme(true);
            }
          }}
          icon={<BgColorsOutlined />}
          type={theme === 'dark' ? 'primary' : undefined}
        />
      </Layout>
    </div>
        //     {/* </Col>
        // </Row> */}

    )
}

export default LoggedInArea
